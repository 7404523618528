@import url(https://use.fontawesome.com/releases/v6.1.2/css/all.css);
.container {
  width: 100%;
}

@media (min-width: 0px) {
  .container {
    max-width: 0px;
  }
}

@media (min-width: 600px) {
  .container {
    max-width: 600px;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1920px;
  }
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 110, 157, var(--tw-bg-opacity)) !important;
}

.rounded-xl {
  border-radius: 0.75rem !important;
}

.rounded-3xl {
  border-radius: 1.5rem !important;
}

.border-none {
  border-style: none !important;
}

.border {
  border-width: 1px !important;
}

.border-b-2 {
  border-bottom-width: 2px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.table {
  display: table !important;
}

.grid {
  display: grid !important;
}

.hidden {
  display: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-9 {
  height: 2.25rem !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-16 {
  height: 4rem !important;
}

.h-32 {
  height: 8rem !important;
}

.h-36 {
  height: 9rem !important;
}

.h-40 {
  height: 10rem !important;
}

.h-48 {
  height: 12rem !important;
}

.h-60 {
  height: 15rem !important;
}

.h-64 {
  height: 16rem !important;
}

.h-72 {
  height: 18rem !important;
}

.h-80 {
  height: 20rem !important;
}

.h-96 {
  height: 24rem !important;
}

.h-full {
  height: 100% !important;
}

.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.m-0 {
  margin: 0px !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mr-20 {
  margin-right: 5rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mb-32 {
  margin-bottom: 8rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mb-3 {
  margin-bottom: -0.75rem !important;
}

.-mb-4 {
  margin-bottom: -1rem !important;
}

.-mb-5 {
  margin-bottom: -1.25rem !important;
}

.max-w-xs {
  max-width: 20rem !important;
}

.max-w-lg {
  max-width: 32rem !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-28 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pb-32 {
  padding-bottom: 8rem !important;
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.right-0 {
  right: 0px !important;
}

.left-0 {
  left: 0px !important;
}

.right-2 {
  right: 0.5rem !important;
}

* {
  --tw-shadow: 0 0 #0000;
}

.shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.text-blue {
  --tw-text-opacity: 1 !important;
  color: rgba(5, 110, 157, var(--tw-text-opacity)) !important;
}

.text-primary {
  --tw-text-opacity: 1 !important;
  color: rgba(83, 95, 116, var(--tw-text-opacity)) !important;
}

.text-disabled {
  --tw-text-opacity: 1 !important;
  color: rgba(157, 165, 179, var(--tw-text-opacity)) !important;
}

.text-gray-3 {
  --tw-text-opacity: 1 !important;
  color: rgba(112, 112, 113, var(--tw-text-opacity)) !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.visible {
  visibility: visible !important;
}

.break-normal {
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.break-all {
  word-break: break-all !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-16 {
  width: 4rem !important;
}

.w-32 {
  width: 8rem !important;
}

.w-36 {
  width: 9rem !important;
}

.w-48 {
  width: 12rem !important;
}

.w-64 {
  width: 16rem !important;
}

.w-96 {
  width: 24rem !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/3 {
  width: 33.333333% !important;
}

.w-2\/3 {
  width: 66.666667% !important;
}

.w-3\/4 {
  width: 75% !important;
}

.w-2\/5 {
  width: 40% !important;
}

.w-5\/12 {
  width: 41.666667% !important;
}

.w-10\/12 {
  width: 83.333333% !important;
}

.w-full {
  width: 100% !important;
}

.w-max {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.z-40 {
  z-index: 40 !important;
}

.transform {
  --tw-translate-x: 0 !important;
  --tw-translate-y: 0 !important;
  --tw-rotate: 0 !important;
  --tw-skew-x: 0 !important;
  --tw-skew-y: 0 !important;
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes fbPing {
  100% {
    transform: scale(1.05, 1.25);
    opacity: 0;
  }
}

.animate-fbPing {
  animation: fbPing .5s linear 5 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:not(path) {
  revert: all;
}

@media (min-width: 1920px) {
}

@media (min-width: 1280px) {
}

@media (min-width: 960px) {
  .md\:h-72 {
    height: 18rem !important;
  }

  .md\:h-80 {
    height: 20rem !important;
  }

  .md\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
}

@media (min-width: 600px) {
}

@media (min-width: 0px) {
}

#root {
  height: 100vh;
  position: absolute;
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.StripeElement {
  width: 100%;
  border: 1px solid #bbc3d1;
  border-radius: 8px;
  padding: 12px 10px;
  font-size: 14;
  font-family: Poppins;
  color: #535f74;
}

.StripeElement--focus {
  border-color: #056e9d;
  outline: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

